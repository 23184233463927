import "./components/base";
import "./components/cart-order";
import "./components/top-search";
import "./components/popup-menu";
import "./components/sidebar";
import "./components/tree-search";
import "./components/catalog-modal";
import "./components/popup-calc";
import "./components/tabs";
// import "./components/decor-nav";
import "./components/fancybox";
import "./components/decor-popup";
//import "./components/data-table";
import "./components/data-table-order";
import "./components/data-table-preorders";
import "./components/data-table-crm-leads";
import "./components/data-table-user";
import "./components/datepicker";
import "./components/svg";
import "./components/header";
import "./components/catalog";
import "./components/page-auth";
import "./components/page-lk";
import "./components/page-cart";
import "./components/page-fineapp";
//import "./components/charts";
//import "./components/widget";
// import "./facet/app";

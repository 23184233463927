$(function () {

	$(document).on('click', '.js-add-fav', function () {
		let tr = $(this).closest('tr');

		if (tr) {
			tr.find('.js-add-fav').toggleClass("show-svg-fill");
		} else {
			$(this).toggleClass("show-svg-fill");
		}

	});

});
